<template>
  <div class="admin">
    <popup v-model="popup" :forced="true" bgColor="white">
      <div class="big-white-box">
        <img src="../../assets/dam.png" width="100" />
        <br />
        <label>
          סיסמא
          <input
            class="order-input"
            type="password"
            @input="checkPassword"
            v-model="password"
          />
        </label>
      </div>
    </popup>
    <div class="admin-menu" v-if="loggedIn">
      <router-link class="admin-logo" to="/">
        <img src="../../assets/dam.png" alt="דם המכבים" />
      </router-link>
      <router-link class="menu-item" to="/admin">
        <span class="icon-font">dashboard</span>
        דשבורד
      </router-link>
      <router-link class="menu-item" to="/admin/locations">
        <span class="icon-font">explore</span>
        נקודות פיזור
      </router-link>
      <router-link class="menu-item" to="/admin/coupons">
        <span class="icon-font">payments</span>
        קופונים
      </router-link>
      <router-link class="menu-item" to="/admin/orders">
        <span class="icon-font">shopping_bag</span>

        הזמנות
      </router-link>
      <router-link class="menu-item" to="/admin/families">
        <span class="icon-font">diversity_1</span>
        משפחות שכולות
      </router-link>
      <router-link class="menu-item" to="/admin/companies">
        <span class="icon-font">domain</span>

        הזמנות חברות
      </router-link>

      <router-link class="menu-item" to="/admin/schools">
        <span class="icon-font">school</span>
        הזמנות בתי ספר
      </router-link>

      <router-link class="menu-item" to="/admin/nonprofits">
        <span class="icon-font">psychiatry</span>
        עמותות שותפות
      </router-link>
      <router-link class="menu-item" to="/admin/email">
        <span class="icon-font">mail</span>
        מיילים
      </router-link>
      <div class="admin-menu-space"></div>
    </div>
    <router-view v-if="!popup" class="main-view"></router-view>
  </div>
</template>

<script>
export default {
  name: "admin",
  data() {
    return {
      popup: true,
      password: "",
      loggedIn: false,
      publicLocations: ["SingleLocation"],
    };
  },
  mounted() {
    this.loggedIn = !!this.$cookies.get("password");
    this.popup =
      !this.loggedIn && !this.publicLocations.includes(this.$route.name);
  },
  methods: {
    checkPassword() {
      if (this.password == "dama") {
        this.popup = false;
        this.$cookies.set("password", true);
      }
    },
  },
};
import "buefy/dist/buefy.css";
</script>

<style scoped>
.admin {
  display: flex;
  overflow: none;
}

.icon-font {
  font-family: "Material Symbols Outlined";
  display: inline-block;
  transform: translateY(4px);
  opacity: 0.5;
  margin-left: 5px;
}

.menu-item {
  color: white;
  padding: 10px 20px;
  font-size: 20px;
}

.menu-item.router-link-exact-active.router-link-active {
  background: white;
  color: #20377b;
}

.admin-menu {
  width: 210px;
  background-color: #20377b;
  height: 100vh;
  display: flex;
  position: fixed;
  color: white;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  /* box-shadow: 0 0 10px #20377b27; */
  z-index: 99;
}

.main-view {
  margin-inline-start: 210px;
  width: calc(100vw - 210px);
  /* padding-inline-start: 2rem; */
  height: 100vh;
}

.admin-logo {
  /* position: absolute; */
  top: 0px;
  filter: grayscale(100%) brightness(1000%);
  width: 100px;
  margin: 20px auto auto;
}

.big-white-box {
  text-align: center;
}

.big-white-box img {
  margin-bottom: 20px;
}

.admin-menu-space {
  flex-basis: 50%;
}

@media screen and (max-width: 767px) {
  .admin-menu {
    width: 100%;
    height: 60px;
    display: flex;
    position: fixed;
    color: white;
    flex-direction: row;
    align-items: center;
    bottom: 0;
    padding: 0px 5px;
    overflow-x: auto;
    justify-content: flex-start;
  }

  .main-view {
    margin-inline-start: 0;
    width: 100%;
    height: calc(100vh - 60px);
    overflow: auto;
  }

  .admin-menu-space {
    flex-basis: 0%;
  }

  .icon-font {
    display: block;
    font-size: 20px;
  }

  .menu-item {
    text-align: center;
    flex: 1 0 20%;
    font-size: 12px;
    white-space: nowrap;
    padding: 0 5px 5px 5px;
  }

  .admin-logo {
    flex: 1 1 20%;
    width: 90px;
    margin: 10px 0 0 0;
  }

  .menu-item.router-link-exact-active.router-link-active {
    border-radius: 10px;
  }
}
</style>
